import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { map } from 'rxjs/operators';
import {
  ICourse,
  ICurriculum,
  ITranslation,
  NewCurriculumService,
  TypeCode,
} from '@services/new-curriculum.service';
import { NewCurriculum } from './new-curriculum.actions';
import { forkJoin } from 'rxjs';

// curriculum from april
export class NewCurriculumStateModel {
  courses: ICourse[];
  organizingIdeas: ICurriculum[];
  coursesDec2021: ICourse[];
  organizingIdeasDec2021: ICurriculum[];
  coursesApr2022: ICourse[];
  organizingIdeasApr2022: ICurriculum[];
  courses20220912: ICourse[];
  organizingIdeas20220912: ICurriculum[];
  courses20230308: ICourse[];
  organizingIdeas20230308: ICurriculum[];
  coursesmar29_2023: ICourse[];
  organizingIdeasmar29_2023: ICurriculum[];
  courses20240315: ICourse[];
  organizingIdeas20240315: ICurriculum[];
}

const defaults: NewCurriculumStateModel = {
  courses: [],
  organizingIdeas: [],
  coursesDec2021: [],
  organizingIdeasDec2021: [],
  coursesApr2022: [],
  organizingIdeasApr2022: [],
  courses20220912: [],
  organizingIdeas20220912: [],
  courses20230308: [],
  organizingIdeas20230308: [],
  coursesmar29_2023: [],
  organizingIdeasmar29_2023: [],
  courses20240315: [],
  organizingIdeas20240315: [],
};

@State<NewCurriculumStateModel>({
  name: 'newCurriculum',
  defaults: defaults,
})
@Injectable()
export class NewCurriculumState {
  constructor(private newCurriculumService: NewCurriculumService) {}

  @Action(NewCurriculum.Load)
  onLoad(ctx: StateContext<NewCurriculumStateModel>, action: NewCurriculum.Load) {
    const state = ctx.getState();
    if (state.courses.length > 0 && state.organizingIdeas.length > 0) {
      return;
    }

    const folder20240315 = `20240315`;
    const folder20230308 = `20230308`;
    const folder20220912 = `20220912`;
    const folderApr2022 = `apr2022`;
    const foldermar29_2023 = `mar29_2023`;
    return forkJoin({
      courses: this.newCurriculumService.getGqs(),
      curriculum: this.newCurriculumService.getOrganizingIdeas(),
      coursesDec2021: this.newCurriculumService.getGqsDec21(),
      curriculumDec2021: this.newCurriculumService.getOrganizingIdeasDec21(),
      coursesApr2022: this.newCurriculumService.getCourses(folderApr2022),
      curriculumApr2022: this.newCurriculumService.getCurriculum(folderApr2022),
      courses20220912: this.newCurriculumService.getCourses(folder20220912),
      curriculum20220912: this.newCurriculumService.getCurriculum(folder20220912),
      courses20230308: this.newCurriculumService.getCourses(folder20230308),
      curriculum20230308: this.newCurriculumService.getCurriculum(folder20230308),
      coursesmar29_2023: this.newCurriculumService.getCourses(foldermar29_2023),
      organizingIdeasmar29_2023: this.newCurriculumService.getCurriculum(foldermar29_2023),
      courses20240315: this.newCurriculumService.getCourses(folder20240315),
      curriculum20240315: this.newCurriculumService.getCurriculum(folder20240315),
    }).pipe(
      map((x) => {
        ctx.patchState({
          courses: x.courses,
          organizingIdeas: x.curriculum,
          coursesDec2021: x.coursesDec2021,
          organizingIdeasDec2021: x.curriculumDec2021,
          coursesApr2022: x.coursesApr2022,
          organizingIdeasApr2022: x.curriculumApr2022,
          courses20220912: x.courses20220912,
          organizingIdeas20220912: x.curriculum20220912,
          courses20230308: x.courses20230308,
          organizingIdeas20230308: x.curriculum20230308,
          coursesmar29_2023: x.coursesmar29_2023,
          organizingIdeasmar29_2023: x.organizingIdeasmar29_2023,
          courses20240315: x.courses20240315,
          organizingIdeas20240315: x.curriculum20240315,
        });
      })
    );
  }

  @Selector()
  static courses(state: NewCurriculumStateModel): ICourse[] {
    return state.courses;
  }

  @Selector()
  static organizingIdeas(state: NewCurriculumStateModel): ICurriculum[] {
    return state.organizingIdeas;
  }

  @Selector()
  static coursesDec2021(state: NewCurriculumStateModel): ICourse[] {
    return state.coursesDec2021;
  }

  @Selector()
  static organizingIdeasDec2021(state: NewCurriculumStateModel): ICurriculum[] {
    return state.organizingIdeasDec2021;
  }

  @Selector()
  static coursesApr2022(state: NewCurriculumStateModel): ICourse[] {
    return state.coursesApr2022;
  }

  @Selector()
  static organizingIdeasApr2022(state: NewCurriculumStateModel): ICurriculum[] {
    return state.organizingIdeasApr2022;
  }

  @Selector()
  static courses20220912(state: NewCurriculumStateModel): ICourse[] {
    return state.courses20220912;
  }

  @Selector()
  static organizingIdeas20220912(state: NewCurriculumStateModel): ICurriculum[] {
    return state.organizingIdeas20220912;
  }

  @Selector()
  static courses20230308(state: NewCurriculumStateModel): ICourse[] {
    return state.courses20230308;
  }

  @Selector()
  static organizingIdeas20230308(state: NewCurriculumStateModel): ICurriculum[] {
    return state.organizingIdeas20230308;
  }

  @Selector()
  static courses20240315(state: NewCurriculumStateModel): ICourse[] {
    return state.courses20240315;
  }

  @Selector()
  static organizingIdeas20240315(state: NewCurriculumStateModel): ICurriculum[] {
    return state.organizingIdeas20240315;
  }

  private static getCurriculumDiffs(old: ICurriculum[], current: ICurriculum[]): ICurriculumDiff[] {
    if (!(old?.length > 0 && current?.length > 0)) {
      return [];
    }

    let result: ICurriculumDiff[] = [];

    current.map((x) => {
      const oldCurriculum = old.find((y) => y.code === x.code);
      return {
        code: x.code,
        parentCurriculumCode: x.parentCurriculumCode,
        categoryCode: x.categoryCode,
        typeCode: x.typeCode,
        keywordCodes: x.keywordCodes,
        courseCodes: x.courseCodes,
        curriculums: this.getCurriculumDiffs([], x.curriculums),
        oldContents: oldCurriculum?.contents ?? null,
        newContents: x.contents,
        sortOrder: x.sortOrder,
      };
    });

    return result;
  }
}

export interface ICourseDiff {
  code: string;
  oldNames: ITranslation;
  newNames: ITranslation;
  keywordCodes: string[];
  categoryCode: string;
  intendedGradeLevel: string;
  curriculums: ICurriculumDiff[];
}

export interface ICurriculumDiff {
  code: string;
  parentCurriculumCode: string;
  categoryCode: string;
  typeCode: TypeCode;
  keywordCodes: string[];
  courseCodes: string[];
  curriculums: ICurriculumDiff[];
  oldContents: ITranslation;
  newContents: ITranslation;
  sortOrder: number;
}
