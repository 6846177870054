import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { BcCurriculum } from './bc-curriculum.actions';
import { Filter } from './filter.actions';
import { SaskCurriculum } from './sask-curriculum.actions';

export interface IOption {
  id: string;
  name: string;
}

export interface IGradeOption extends IOption {
  curriculumIds: string[];
}

export interface ISubjectOption extends IOption {
  curriculumIds: string[];
}

export interface ICurriculumOption extends IOption {
  curriculumType: CurriculumType;
  curriculumName?: string;
  courseName?: string;
  organizingIdeasName?: string;
  diffCurriculumName?: string;
  diffName?: string;
}

export interface IFilters {
  grades: IGradeOption[];
  subjects: ISubjectOption[];
  languages: IOption[];
  curriculumOptions: ICurriculumOption[];
}

export interface ISelectedFilters {
  selectedSubjectId: string;
  selectedGradeId: string;
  selectedCurriculumId: string;
}

export enum CurriculumType {
  AlbertaCurrent,
  AlbertaDraft2018,
  AlbertaDraft2021,
  AlbertaDraftDec2021,
  AlbertaDraftApr2022,
  Alberta20220912,
  Alberta20230308,
  Alberta20240315,
  AlbertaGuidingFramework2016,
  AlbertaGuidingFramework2020,
  Sask,
  Bc,
}

export enum PanelType {
  Left,
  Right,
}
export class FilterStateModel {
  filters: IFilters;
  selectedLeft: ISelectedFilters;
  selectedRight: ISelectedFilters;
  nlaCurriculumOptions: ICurriculumOption[];
}

const defaults: FilterStateModel = {
  filters: {
    curriculumOptions: [],
    grades: [],
    languages: [],
    subjects: [],
  },
  selectedLeft: {
    selectedGradeId: 'K',
    selectedSubjectId: 'LANENG',
    selectedCurriculumId: '2022apr',
  },
  selectedRight: {
    selectedGradeId: 'K',
    selectedSubjectId: 'LANENG',
    selectedCurriculumId: '20220912',
  },
  nlaCurriculumOptions: [
    {
      id: '2021',
      name: 'Alberta March Draft 2021',
      curriculumType: CurriculumType.AlbertaDraft2021,
      curriculumName: 'albertaDraft2021',
      courseName: 'courses',
      organizingIdeasName: 'organizingIdeas',
      diffCurriculumName: null,
      diffName: null,
    },
    {
      id: '2021dec',
      name: 'Alberta December Draft 2021',
      curriculumType: CurriculumType.AlbertaDraftDec2021,
      curriculumName: 'albertaDraftDec2021',
      courseName: 'coursesDec2021',
      organizingIdeasName: 'organizingIdeasDec2021',
    },
    {
      id: '2022apr',
      name: 'Alberta April Pilot 2022',
      curriculumType: CurriculumType.AlbertaDraftApr2022,
      curriculumName: 'albertaDraftApr2022',
      courseName: 'coursesApr2022',
      organizingIdeasName: 'organizingIdeasApr2022',
    },
    {
      id: '20220912',
      name: 'Alberta September 2022',
      curriculumType: CurriculumType.Alberta20220912,
      curriculumName: 'alberta20220912',
      courseName: 'courses20220912',
      organizingIdeasName: 'organizingIdeas20220912',
    },
    {
      id: 'mar29_2023',
      name: 'Alberta March 29 2023',
      curriculumType: CurriculumType.Alberta20230308,
      curriculumName: 'albertamar29_2023',
      courseName: 'coursesmar29_2023',
      organizingIdeasName: 'organizingIdeasmar29_2023',
    },
    {
      id: '20240315',
      name: 'Alberta March 13 2024',
      curriculumType: CurriculumType.Alberta20240315,
      curriculumName: 'alberta20240315',
      courseName: 'courses20240315',
      organizingIdeasName: 'organizingIdeas20240315',
    },
  ],
};

@State<FilterStateModel>({
  name: 'filter',
  defaults: defaults,
})
@Injectable()
export class FilterState implements NgxsOnInit {
  constructor(private store: Store) {}

  ngxsOnInit(ctx?: StateContext<any>) {
    const nlaCurriculumOptions = this.store.selectSnapshot(FilterState.nlaCurriculumOptions);
    const nlaCurriculumIds = nlaCurriculumOptions.map((x) => x.id);
    ctx.setState(
      patch({
        selectedLeft: patch({
          selectedCurriculumId: nlaCurriculumIds[nlaCurriculumIds.length - 2],
        }),
        selectedRight: patch({
          selectedCurriculumId: nlaCurriculumIds[nlaCurriculumIds.length - 1],
        }),
      })
    );

    const curriculumOptions = [
      { id: 'Current', name: 'Alberta Current', curriculumType: CurriculumType.AlbertaCurrent },
      { id: '2018', name: 'Alberta Draft 2018', curriculumType: CurriculumType.AlbertaDraft2018 },
      ...nlaCurriculumOptions,
      { id: 'sask', name: 'Saskatchewan K-6', curriculumType: CurriculumType.Sask },
      { id: 'bc', name: 'British Columbia K-6', curriculumType: CurriculumType.Bc },
      {
        id: 'gf2016',
        name: 'Alberta Guiding Framework 2016',
        curriculumType: CurriculumType.AlbertaGuidingFramework2016,
      },
      {
        id: 'gf2020',
        name: 'Alberta Guiding Framework 2020',
        curriculumType: CurriculumType.AlbertaGuidingFramework2020,
      },
    ];

    const grades = [
      {
        name: 'Kindergarten',
        id: 'K',
        curriculumIds: ['Current', '2018', ...nlaCurriculumIds, 'sask', 'bc'],
      },
      {
        name: 'Grade 1',
        id: '1',
        curriculumIds: ['Current', '2018', ...nlaCurriculumIds, 'sask', 'bc'],
      },
      {
        name: 'Grade 2',
        id: '2',
        curriculumIds: ['Current', '2018', ...nlaCurriculumIds, 'sask', 'bc'],
      },
      {
        name: 'Grade 3',
        id: '3',
        curriculumIds: ['Current', '2018', ...nlaCurriculumIds, 'sask', 'bc'],
      },
      {
        name: 'Grade 4',
        id: '4',
        curriculumIds: ['Current', '2018', ...nlaCurriculumIds, 'sask', 'bc'],
      },
      {
        name: 'Grade 5',
        id: '5',
        curriculumIds: ['Current', ...nlaCurriculumIds, 'sask', 'bc'],
      },
      {
        name: 'Grade 6',
        id: '6',
        curriculumIds: ['Current', ...nlaCurriculumIds, 'sask', 'bc'],
      },
    ];
    const subjects = [
      { name: 'English Language Arts', id: 'LANENG', curriculumIds: ['Current', 'sask'] },
      {
        name: 'English Language Arts and Literature',
        id: 'LANENG',
        curriculumIds: ['2018', ...nlaCurriculumIds],
      },
      { name: 'Fine Arts', id: 'FARART', curriculumIds: ['Current', 'sask'] },
      {
        name: 'Fine Arts - Dance',
        id: 'FARDAN',
        curriculumIds: ['2018', ...nlaCurriculumIds],
      },
      {
        name: 'Fine Arts - Drama',
        id: 'FARDMA',
        curriculumIds: ['2018', ...nlaCurriculumIds],
      },
      {
        name: 'Fine Arts - Music',
        id: 'FARGEN',
        curriculumIds: ['2018', ...nlaCurriculumIds],
      },
      {
        name: 'Fine Arts - Visual Arts',
        id: 'FARART',
        curriculumIds: ['2018', ...nlaCurriculumIds],
      },
      {
        name: 'Français immersion et littérature',
        id: 'OLAFLA',
        curriculumIds: ['2018', ...nlaCurriculumIds],
      },
      {
        name: 'Français langue première et littérature',
        id: 'LANFRA',
        curriculumIds: ['Current', '2018', ...nlaCurriculumIds],
      },
      { name: 'French Language Arts - Immersion', id: 'OLAFLA', curriculumIds: ['Current'] },
      { name: 'Health / Career & Life Management', id: 'CALM', curriculumIds: ['Current'] },
      { name: 'Health Education', id: 'CALM', curriculumIds: ['sask'] },
      {
        name: 'Mathematics',
        id: 'MAT',
        curriculumIds: ['Current', '2018', ...nlaCurriculumIds, 'sask'],
      },
      { name: 'Physical Education', id: 'PDE', curriculumIds: ['Current', 'sask'] },
      {
        name: 'Physical Education and Wellness',
        id: 'PDE',
        curriculumIds: ['2018', ...nlaCurriculumIds],
      },
      {
        name: 'Science',
        id: 'SCI',
        curriculumIds: ['Current', '2018', ...nlaCurriculumIds, 'sask', 'bc'],
      },
      {
        name: 'Social Studies',
        id: 'SSS',
        curriculumIds: ['Current', '2018', ...nlaCurriculumIds, 'sask', 'bc'],
      },
    ];
    const languages = [
      { id: 'en-ca', name: 'English' },
      { id: 'fr-ca', name: 'French' },
    ];

    ctx.patchState({
      filters: {
        curriculumOptions: curriculumOptions,
        grades: grades,
        languages: languages,
        subjects: subjects,
      },
    });
  }

  @Action(Filter.Update)
  onUpdate(ctx: StateContext<FilterStateModel>, action: Filter.Update) {
    if (action.selectedFilters.selectedCurriculumId === 'sask') {
      ctx.dispatch([new SaskCurriculum.Load()]);
    } else if (action.selectedFilters.selectedCurriculumId === 'bc') {
      ctx.dispatch([new BcCurriculum.Load()]);
    }

    if (action.panelType === PanelType.Left) {
      ctx.patchState({
        selectedLeft: action.selectedFilters,
      });
    } else if (action.panelType === PanelType.Right) {
      ctx.patchState({
        selectedRight: action.selectedFilters,
      });
    }

    if (action.isUpdatingUrl) {
      ctx.dispatch([new Filter.UpdateUrl()]);
    }

    return true;
  }

  @Action(Filter.NextGrade)
  onNextGrade(ctx: StateContext<FilterStateModel>, action: Filter.NextGrade) {
    const state = ctx.getState();
    const selected = action.panelType === PanelType.Left ? state.selectedLeft : state.selectedRight;
    const grades = state.filters.grades.filter((x) =>
      x.curriculumIds.some((y) => y === selected.selectedCurriculumId)
    );
    const ix = grades.findIndex((x) => x.id === selected.selectedGradeId);

    if (ix >= grades.length - 1) {
      return;
    }

    if (action.panelType === PanelType.Left) {
      ctx.setState(
        patch({
          selectedLeft: patch({
            selectedGradeId: grades[ix + 1].id,
          }),
        })
      );
    } else {
      ctx.setState(
        patch({
          selectedRight: patch({
            selectedGradeId: grades[ix + 1].id,
          }),
        })
      );
    }

    ctx.dispatch([new Filter.UpdateUrl()]);
  }

  @Action(Filter.PrevGrade)
  onPrevGrade(ctx: StateContext<FilterStateModel>, action: Filter.PrevGrade) {
    const state = ctx.getState();
    const selected = action.panelType === PanelType.Left ? state.selectedLeft : state.selectedRight;
    const grades = state.filters.grades.filter((x) =>
      x.curriculumIds.some((y) => y === selected.selectedCurriculumId)
    );
    const ix = grades.findIndex((x) => x.id === selected.selectedGradeId);

    if (ix <= 0) {
      return;
    }

    if (action.panelType === PanelType.Left) {
      ctx.setState(
        patch({
          selectedLeft: patch({
            selectedGradeId: grades[ix - 1].id,
          }),
        })
      );
    } else {
      ctx.setState(
        patch({
          selectedRight: patch({
            selectedGradeId: grades[ix - 1].id,
          }),
        })
      );
    }

    ctx.dispatch([new Filter.UpdateUrl()]);
  }

  @Action(Filter.UpdateUrl)
  onUpdateUrl(ctx: StateContext<FilterStateModel>, actions: Filter.UpdateUrl) {
    const state = ctx.getState();

    ctx.dispatch([
      new Navigate(
        [
          `/compare/${
            JSON.stringify(state.selectedLeft) + '|' + JSON.stringify(state.selectedRight)
          }`,
        ],
        {},
        {
          replaceUrl: actions.isReplacingHistory,
        }
      ),
    ]);
  }

  @Selector()
  static selectedLeft(state: FilterStateModel): ISelectedFilters {
    return { ...state.selectedLeft };
  }

  @Selector()
  static selectedRight(state: FilterStateModel): ISelectedFilters {
    return { ...state.selectedRight };
  }

  @Selector()
  static leftFilters(state: FilterStateModel): IFilters {
    return this.getFiltersBy(state.selectedLeft.selectedCurriculumId, state.filters);
  }

  @Selector()
  static rightFilters(state: FilterStateModel): IFilters {
    return this.getFiltersBy(state.selectedRight.selectedCurriculumId, state.filters);
  }

  @Selector()
  static nlaCurriculumOptions(state: FilterStateModel): ICurriculumOption[] {
    return state.nlaCurriculumOptions.map((x, i) => {
      return {
        ...x,
        diffCurriculumName: i === 0 ? '' : state.nlaCurriculumOptions[i - 1].curriculumName,
        diffName: i === 0 ? '' : state.nlaCurriculumOptions[i - 1].name,
      };
    });
  }

  static getFiltersBy(curriculumId: string, filters: IFilters): IFilters {
    const result: IFilters = {
      curriculumOptions: filters.curriculumOptions,
      grades: filters.grades.filter((x) => x.curriculumIds.some((y) => y === curriculumId)),
      languages: filters.languages,
      subjects: filters.subjects.filter((x) => x.curriculumIds.some((y) => y === curriculumId)),
    };

    return result;
  }
}
